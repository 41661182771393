import React, {memo, useEffect, useState} from 'react';
import './advancedShapes.css';
import usePrevious from '../../../../hooks/usePrevious';
import {ifHasAlpha} from "../utils";
import {SHAPE_DEFAULT_COLOR} from "../constants";

const LineArrow = ({ width, height, strokeColor, fillColor, arrowWidth, arrowLineWidth }) => {
    const [arrowWidthState, setArrowWidthState] = useState(arrowWidth);
    const [lineWidthState, setLineWidthState] = useState(width - arrowWidth);
    const [heightState, setHeightState] = useState(height);

    const fillHasAlpha = ifHasAlpha(fillColor)

    const prevWidth = usePrevious(width);
    const prevHeight = usePrevious(height);

    useEffect(() => {
        // Proportional resizing when corner handles are dragged

        if (!prevHeight && height) {
            setHeightState(height);
        }

        if (!prevWidth && !prevHeight) {
            return;
        }
        if (prevWidth !== width && prevHeight !== height) {
            const widthRatio = width / prevWidth;
            const heightRatio = height / prevHeight;
            setArrowWidthState((prev) => prev * widthRatio);
            setLineWidthState((prev) => prev * widthRatio);
            setHeightState((prev) => prev * heightRatio);
        }
        // Independent width adjustment when side handles are dragged
        else if (prevWidth !== width && prevHeight === height) {
            const widthDifference = width - prevWidth;
            setLineWidthState((prev) => prev + widthDifference);
        }
        // Vertical scaling when top/bottom handles are dragged
        else if (prevHeight !== height && prevWidth === width) {
            setHeightState(height);
        }
    }, [width, height, prevWidth, prevHeight]);

    return (
        <div
            className="line-arrow--target"
            style={{
                display: 'flex',
                height: heightState,
            }}
        >
            <div className="line-arrow--line" style={{width: lineWidthState}}>
                <svg
                    viewBox={`0 0 100 ${heightState}`}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width={fillHasAlpha ? "100%" : "101%"}
                    height="100%"
                >
                    <rect
                        x="0"
                        y={heightState * 0.35}
                        width={fillHasAlpha ? "100%" : "101%"}
                        height={heightState * 0.3}
                        fill={fillColor || SHAPE_DEFAULT_COLOR}
                    />
                    <path
                        d={`M 0 ${heightState * 0.35} H 100 M 0 ${heightState * 0.35} V ${heightState * 0.35 + heightState * 0.3} M 0 ${heightState * 0.35 + heightState * 0.3} H 100`}
                        fill={"none"}
                        stroke={strokeColor}
                        strokeWidth={2}
                    />
                </svg>
            </div>

            <div className="line-arrow--arrow" style={{width: arrowWidthState}}>
                <svg preserveAspectRatio="none"
                     width="100%"
                     height="100%" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path d="M 0 0 L 200 100 L 0 200 M 0 0 L 0 70 M 0 280 L 0 130"
                          fill={fillColor || SHAPE_DEFAULT_COLOR}
                          stroke={strokeColor || SHAPE_DEFAULT_COLOR}
                          strokeWidth="2"
                          strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
};

export default memo(LineArrow);
